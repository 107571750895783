<template>
  <div class="container">
    <van-list>
      <van-cell>
        <template slot="default">
          <div class="header-warp">
            <h4>产品品名：{{ erpVendorIntransitOrders.productName }}</h4>
          </div>
        </template>
      </van-cell>
      <van-cell>
        <template slot="default">
          <div class="detail-wrap">
            <div class="item">
              <p>合同号：{{ erpVendorIntransitOrders.contractNumber }}</p>
              <p>应交数量：{{ erpVendorIntransitOrders.quantityPurchased }}</p>
              <p>已交数量：{{ erpVendorIntransitOrders.quantityDelivered }}</p>
              <p>
                预到货日：{{ erpVendorIntransitOrders.expectedArrivalTime }}
              </p>
              <p>
                反馈：<input
                  type="text"
                  v-model="erpVendorIntransitOrders.supplierRemark"
                  placeholder="如果需要延期或特殊原因请输入"
                />
              </p>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>

    <div class="footer">
      <button class="save-button" @click="updateData">保存</button>
    </div>
  </div>
</template>

<script>
import api from '@/api/erpVendorIntransitOrders'
export default {
  name: 'process',

  props: {
    msg: String,
  },

  data() {
    return {
      // 产品id
      id: 0,
      // 在途订单详细
      erpVendorIntransitOrders: {},
    }
  },

  created() {
    this.id = this.$route.params.id
    this.fetchData(this.id)
  },

  methods: {
    // 查询单条详细信息
    fetchData(id) {
      api.supplierShow(id).then((response) => {
        this.erpVendorIntransitOrders = response.data
      })
    },

    // 给订单修改数据
    updateData() {
      let orderVo = {
        purchaseOrderId: this.erpVendorIntransitOrders.purchaseOrderId,
        productId: this.erpVendorIntransitOrders.productId,
        quantityPurchased: this.erpVendorIntransitOrders.quantityPurchased,
        contractNumber: this.erpVendorIntransitOrders.contractNumber,
        remark: this.erpVendorIntransitOrders.supplierRemark,
      }
      api
        .supplierUpdateData(orderVo)
        .then((response) => {
          // 添加成功后显示提示
          this.$toast.success('添加成功！')
          // 通知父页面刷新
          const page = this.$route.query.page || 1 // 假设当前页码从路由传递
          window.parent.postMessage({ action: 'onLoad', page }, '*') // 发送当前页码
        })
        .catch((error) => {
          // 添加失败时提示错误信息
          this.$toast.fail('添加失败，请重试！')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 86px;
  .header-warp {
    position: relative;
    h4 {
      font-size: 16px;
      margin: 5px;
    }
    p {
      font-size: 16px;
      margin: 5px;
    }
    .pass {
      color: #4cb971;
      margin: 5px;
    }
    .refused {
      color: #eb8473;
      margin: 5px;
    }
  }
  .detail-wrap {
    .item {
      h5 {
        color: #838485;
        margin: 5px;
      }
      p {
        color: #1b1f22;
        font-size: 16px;
        margin: 5px;
      }
    }
  }
  .result {
    font-size: 14px;
    h4,
    p {
      margin: 5px;
      font-size: 14px;
    }
  }
  .seal-wrap {
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .notice {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    padding: 8px 10px;
    background: #fefbe8;
    position: fixed;
    bottom: 53px;
    z-index: 10;
    p {
      flex: 1;
      margin: 4px;
    }
    .pass {
      color: #07c160;
      margin-right: 20px;
    }
  }
  .footer {
    // height: 50px;
    padding: 10px;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10;

    .save-button {
      flex: 1; /* 占据剩余空间 */
      padding: 10px 20px; /* 内边距 */
      background-color: #007bff; /* 蓝色背景 */
      color: #fff; /* 白色文字 */
      border: none; /* 去掉默认边框 */
      border-radius: 4px; /* 圆角边框 */
      font-size: 16px; /* 字体大小 */
      text-align: center; /* 居中对齐 */
      cursor: pointer; /* 鼠标指针 */
      transition: background-color 0.3s, box-shadow 0.3s; /* 动效 */
    }

    .save-button:hover {
      background-color: #0056b3; /* 悬停时的背景颜色 */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 悬停时的阴影效果 */
    }

    .save-button:active {
      background-color: #003d80; /* 按下时的背景颜色 */
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* 按下时的内嵌阴影 */
    }
  }
}

/deep/ .van-cell {
  position: inherit;
  overflow: visible;
}

/deep/ .van-cell__value {
  position: inherit;
  overflow: visible;
}
</style>
